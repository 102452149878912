// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-aetheryte-js": () => import("/opt/build/repo/src/pages/Aetheryte.js" /* webpackChunkName: "component---src-pages-aetheryte-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offthe-rails-js": () => import("/opt/build/repo/src/pages/OfftheRails.js" /* webpackChunkName: "component---src-pages-offthe-rails-js" */),
  "component---src-pages-rcr-3-k-js": () => import("/opt/build/repo/src/pages/RCR3K.js" /* webpackChunkName: "component---src-pages-rcr-3-k-js" */),
  "component---src-pages-saguar-craft-js": () => import("/opt/build/repo/src/pages/SaguarCraft.js" /* webpackChunkName: "component---src-pages-saguar-craft-js" */),
  "component---src-pages-sibyl-js": () => import("/opt/build/repo/src/pages/Sibyl.js" /* webpackChunkName: "component---src-pages-sibyl-js" */),
  "component---src-pages-thousand-rooms-translation-js": () => import("/opt/build/repo/src/pages/ThousandRoomsTranslation.js" /* webpackChunkName: "component---src-pages-thousand-rooms-translation-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

